<template>
  <div class="d-flex error-page justify-center">
    <div class="error-page__banner">
      <div class="error-code text-center">
        <h1 class="error-code__title">401</h1>
        <h2>Tu sesión ha expirado</h2>
        <v-btn color="secondary"
               class="mt-2 elevation-0 text--primary text-none"
               @click="goToLogin">
          Iniciar sesión
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goToLogin () {
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
